/*

 ######     ###    ########  ########   ######  
##    ##   ## ##   ##     ## ##     ## ##    ## 
##        ##   ##  ##     ## ##     ## ##       
##       ##     ## ########  ##     ##  ######  
##       ######### ##   ##   ##     ##       ## 
##    ## ##     ## ##    ##  ##     ## ##    ## 
 ######  ##     ## ##     ## ########   ######  

*/

.card {
	@include card();
	margin-bottom: 20px;
	@media (min-width: $breakpoint-lg) {
		margin-bottom: 0;
	}
}

.card--tan {
	@include card--tan();
}

.card__content {
	padding: 20px;
	@media (min-width: $breakpoint-lg) {
		padding: 40px;
	}
	p,
	ul {
		font-size: 1rem;
	}
}

.card__content--padded {
	@media (min-width: $breakpoint-md) {
		padding: 20px 40px;
	}
	@media (min-width: $breakpoint-lg) {
		padding: 60px;
	}
	@media (min-width: $breakpoint-xl) {
		padding: 60px 120px;
	}
}
.card .table {
	margin: 0;
}
