/*
	
########    ###    ########  ##       ########  ######  
   ##      ## ##   ##     ## ##       ##       ##    ## 
   ##     ##   ##  ##     ## ##       ##       ##       
   ##    ##     ## ########  ##       ######    ######  
   ##    ######### ##     ## ##       ##             ## 
   ##    ##     ## ##     ## ##       ##       ##    ## 
   ##    ##     ## ########  ######## ########  ######  

*/

$header-color: $white;
$row-border-color: #bebebe;

.form {
	display: flex;
	flex-direction: column;
}

.table--responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
}
.table {
	margin: 20px 0;
	width: 100%;
	max-width: 100%;
	thead {
		tr {
			th {
				background: $blue--navy;
				color: $header-color;
				text-transform: uppercase;
				font-size: 0.75em;
				font-weight: 500;
				@media (max-width: ($breakpoint-lg - 1px)) {
					position: -webkit-sticky;
					position: sticky;
					top: 0;
				}
			}
		}
		tr.th--centered {
			th {
				text-align: center;
			}
		}
		tr:not(:first-child) {
			th {
				background: $blue--staff;
				text-transform: none;
			}
		}
	}
	th,
	td {
		padding: 10px;
		border-right: 2px solid $white;
		border-bottom: 2px solid $white;
		text-align: left;
		line-height: 1;
		&:last-child {
			border-right: 0;
		}
		@media (min-width: $breakpoint-lg) {
			padding: 10px 25px;
		}
		&.text--right {
			text-align: right;
		}
	}
	th:first-child {
		@media (max-width: ($breakpoint-lg - 1px)) {
			position: sticky;
			left: 0;
			z-index: 2;
		}
	}
	tbody {
		th, td.th-header {
			background: $blue--staff;
			color: $white;
		}
		tr {
			&:last-child {
				th,
				td {
					border-bottom: 0;
				}
			}
			&.subtotal,
			.tr--subtotal {
				background-color: $blue--staff;
				color: $white;
			}
			&.tr--last {
				th,
				td {
					background-color: $blue--navy;
					color: $white;
					input {
						color: $white;
					}
				}
			}
		}
	}
	thead th:first-child,
	tfoot th:first-child {
		z-index: 5;
	}
}

table#abs-summary-first {
	margin-top: 0;
}

.table--striped {
	tr:nth-child(even) {
		background: $blue--very-light;
	}
}

.table--text-right {
	th,
	td {
		text-align: right;
		&:first-child {
			text-align: left;
		}
	}
}

.cell--text-right {
	text-align: right;
}

.table-empty {
	font-style: italic;
	text-align: center;
}

.table--small {
	th,
	td {
		@media (min-width: $breakpoint-lg) {
			padding: 10px 10px;
		}
	}
}
.table--no-top-margin {
	margin-top: 0;
}
.table--no-margin {
	margin: 0;
}
.table--admin-split {
	thead tr {
		th:nth-child(2) {
			width: 100px;
		}
		th:nth-child(3) {
			width: 50px;
		}
	}
}
td.no--padding {
	padding: 0;
}
table.table--vertical-baseline {
	tr,
	td,
	th {
		vertical-align: baseline;
	}
}
