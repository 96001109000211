/*
   ###    ########   ######  
  ## ##   ##     ## ##    ## 
 ##   ##  ##     ## ##       
##     ## ########   ######  
######### ##     ##       ## 
##     ## ##     ## ##    ## 
##     ## ########   ######  
*/

.help__card {
	border-top: 1px solid #eee;
	padding: 20px 30px;
	h2 {
		font-size: 1.2rem;
	}
	&.card__content {
		p {
			font-size: 1rem;
		}
		ul {
			font-size: 1rem;
			margin: 0.5em;
			padding: 0.5em;
		}
	}
	@media (min-width: $breakpoint-xl) {
		@include card();
		@include card--tan();
		position: absolute;
		align-self: start;
		left: calc(100% + 20px);
		width: 40%;
		padding: 20px;
		margin-top: -10px; // to match 10px padding on grid row
	}
}
.help__card--inactive {
	@media (min-width: 1px) {
		display: none;
	}
}
.form--abs {
	margin-bottom: 40px;
	> .grid {
		width: 100%;
		.grid__row {
			padding: 10px 0;
			align-items: center;
			position: relative;
		}
		& .grid__row:last-child {
			border-bottom: 0;
		}
		.grid__input input {
			margin-top: 0;
		}
		> .grid__row {
			align-items: flex-start;
			border-bottom: none;
			> .grid__col {
				padding: 0;
			}
			&:last-child {
				margin-bottom: 20px;
			}
		}
	}
	.grid .grid__row .grid__col.card {
		padding: 25px;
	}
	.subtotal {
		background-color: #e4ebf0;
		min-height: 60px;
	}
	.total {
		background-color: $blue--staff;
		color: $white;
	}
	.column--labels {
		display: none;
		@media (min-width: $breakpoint-lg) {
			display: block;
			text-transform: uppercase;
			color: $blue--staff;
			font-weight: 500;
		}
	}
	.column--labels__last--year {
		padding-left: 8px;
	}
	.subtotal input {
		border: 1px solid $gray--border;
		padding: 5px;
	}
	.budget--categories {
		font-weight: 500;
		position: relative;
		@media (min-width: $breakpoint-lg) {
			text-align: right;
		}
	}
	.last--year {
		@media (max-width: ($breakpoint-lg - 1)) {
			&::before {
				content: 'Last year: ';
			}
		}
		color: $gray--border;
	}
	.help__icon {
		display: inline-block;
		svg {
			width: 38px;
			height: 38px;
		}
		&:hover,
		&.active {
			#question-oval {
				fill: $blue--staff;
			}
		}
		#question-oval.question__oval--active {
			fill: $blue--staff;
		}
		@media (min-width: $breakpoint-md) {
			text-align: right;
			svg {
				width: 38px;
				height: 38px;
			}
		}
	}
	input {
		max-width: 90%;
	}
	.alert {
		margin-top: 15px;
	}
}
.before__grid {
	width: 100%;
}
span.note {
	color: $gray--light;
	font-weight: 300;
}
