@import 'variables/colors';
@import 'variables/global';

@import 'tools/mixins';
@import 'tools/grid';

@import 'generic/reset';
@import 'generic/box-sizing';

@import 'elements/body';
@import 'elements/typography';
@import 'elements/buttons';
@import 'elements/forms';
@import 'elements/layout';
@import 'elements/cards';
@import 'elements/tables';
@import 'elements/header';
@import 'elements/nav';
@import 'elements/alerts';
@import 'elements/link-tabs';
@import 'elements/pagination';
// @import 'elements/error';
// @import 'elements/slat';
// @import 'elements/avatar';
// @import 'elements/dialog';
// @import 'elements/hero';
// @import 'elements/badge';
// @import 'elements/embed';
// @import 'elements/popover';
// @import 'elements/manage';
// @import 'elements/404';

@import 'pages/homepage';
@import 'pages/abs';
@import 'pages/funnel';

@import 'tools/print';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~quill/dist/quill.snow.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
