$white: #fff;

$tan: #f2f2f2;

$yellow--gold: #b88c00;
$yellow--light: #f4c42a;

$green: #648d3d;

$red: red;

$gray--text: #171717;
$gray--nav: #4a4a4a;
$gray--light: #7b7b7b;
$gray--border: #c8c8c8;

$blue--navy: #1d3a5a;
$blue--middle: #144981;
$blue--light: #aac1d8;
$blue--very-light: #e4ebf0;
$blue--gray: #5f758d;
$blue--staff: #466b93;
