/*
	
##     ##  #######  ##     ## ######## ########     ###     ######   ######## 
##     ## ##     ## ###   ### ##       ##     ##   ## ##   ##    ##  ##       
##     ## ##     ## #### #### ##       ##     ##  ##   ##  ##        ##       
######### ##     ## ## ### ## ######   ########  ##     ## ##   #### ######   
##     ## ##     ## ##     ## ##       ##        ######### ##    ##  ##       
##     ## ##     ## ##     ## ##       ##        ##     ## ##    ##  ##       
##     ##  #######  ##     ## ######## ##        ##     ##  ######   ######## 

*/

.homepage {
	.feature__image {
		.feature__image--inner {
			height: 200px;
			background-image: url('../../assets/images/campus-homepage-resized.jpg');
			@include background-image--cover();
			@media (min-width: $breakpoint-md) {
				height: 400px;
			}
			@media (min-width: $breakpoint-lg) {
				height: 700px;
			}
		}
	}
	.feature__hero {
		background-color: $white;
		h1 {
			@include h1();
			font-size: 2.2rem;
			text-align: center;
			color: $blue--navy;
			font-weight: 200;
			margin-bottom: 40px;
		}
		h2 {
			text-transform: uppercase;
			font-weight: 500;
			color: $blue--staff;
			font-size: 1.5rem;
		}
		.feature__item {
			padding-left: 100px;
		}
		.feature__item--status {
			background: url('../../assets/images/growth_icon.svg') no-repeat top left;
			background-size: 70px;
		}
		.feature__item--news {
			background: url('../../assets/images/site.svg') no-repeat top left;
			background-size: 70px;
		}
		@media (min-width: $breakpoint-md) {
			margin-top: -100px;
			@include box-shadow(7px 7px 50px 2px rgba(0, 0, 0, 0.1));
			padding: 20px 0 50px 0;
		}
		@media (min-width: $breakpoint-lg) {
			margin-top: -280px;
			padding: 40px 0 100px 0;
			h1 {
				font-size: 3rem;
				margin-bottom: 60px;
			}
			h2 {
				font-size: 1.8rem;
			}
		}
	}
	.testimony {
		text-align: center;
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid $gray--border;
		@media (min-width: $breakpoint-lg) {
			margin: 90px 200px 60px 200px;
			padding-top: 0;
			border-top: 0;
		}
		.testimony__title {
			color: #72a3c3;
			font-size: 1.2rem;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 35px;
		}
		.testimony__content {
			&,
			p {
				font-weight: 300;
				font-size: 2rem;
				line-height: 1;
				margin-bottom: 35px;
			}
		}
		.quote--icon {
			margin-bottom: 35px;
		}
		.testimony__name {
			font-weight: 500;
			font-size: 1.13em;
			margin-bottom: 10px;
		}
		.testimony__institution {
			font-size: 1.13em;
		}
		& + .hr {
			@media (max-width: ($breakpoint-lg - 1px)) {
				border-bottom: 0;
			}
		}
	}
	.abs-report {
		margin: 40px 0 60px 0;
		border-top: 1px solid $gray--border;
		@media (min-width: $breakpoint-md) {
			margin: 100px 0 100px 0;
			border-top: 0;
		}
		@media (min-width: $breakpoint-lg) {
			margin: 120px 0;
		}
		@media (min-width: $breakpoint-xl) {
			margin: 120px 0 180px 0;
		}
	}
	.funnel-report {
		border-top: 1px solid $gray--border;
		@media (min-width: $breakpoint-md) {
			border-top: 0;
		}
		.funnel-report--mobile {
			@media (min-width: $breakpoint-md) {
				display: none;
			}
		}
		.funnel-report--mobile-second {
			display: none;
			@media (min-width: $breakpoint-md) {
				display: block;
			}
		}
		img {
			width: 100%;
		}
	}
	.abs-report,
	.funnel-report {
		&.grid__row {
			align-items: flex-start;
		}
		h2 {
			font-size: 2.8rem;
			@media (min-width: $breakpoint-md) {
				margin-top: 0;
			}
		}
	}
	.join-study {
		background-image: radial-gradient(circle at 34% 0, #dce3e8, #b1c4d4);
		h2 {
			font-size: 2.6rem;
			margin-top: 0;
		}
		.form__element:first-child {
			margin-top: 0;
		}
	}
}
