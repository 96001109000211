h1 {
	@include h1();
}

h2 {
	@include h2();
}

h3 {
	@include h3();
}

h4 {
	@include h4();
}

h5 {
	@include h5();
}

h6 {
	@include h6();
}

.strong,
strong {
	@include strong();
}

a {
	@include link();
}

p,
ul {
	@include paragraph();
}

em,
.italic {
	font-style: italic;
}

blockquote {
	@include paragraph();
	border-left: 6px solid $gray--nav;
	padding: 20px;
}

code {
	@include code();
}

hr,
.hr {
	border: none;
	border-bottom: 1px solid $gray--border;
	margin: 1.5em 0;
}
.hr--thick {
	border-bottom: 5px solid $gray--border;
	margin: 2.5em 0;
}
.hr--shadow {
	@include box-shadow(0 0 19px 0 rgba(0, 0, 0, 0.5));
}

h3 small {
	font-size: 0.7em;
	color: $gray--light;
}

.original_goal_mini_header {
	color: $blue--navy;
}
