/*
   ###    ##       ######## ########  ########  ######  
  ## ##   ##       ##       ##     ##    ##    ##    ## 
 ##   ##  ##       ##       ##     ##    ##    ##       
##     ## ##       ######   ########     ##     ######  
######### ##       ##       ##   ##      ##          ## 
##     ## ##       ##       ##    ##     ##    ##    ## 
##     ## ######## ######## ##     ##    ##     ######  
*/

.alert {
	padding: 15px 20px;
	margin-bottom: 30px;
	background: $tan;
	@include box-shadow(3px 3px 5px 2px rgba(0, 0, 0, 0.1));
	line-height: 1;
	font-size: 1rem;
	p,
	h1,
	h2,
	h3,
	h4 {
		margin: 0;
		line-height: 1;
		font-size: 1rem;
	}
	h1,
	h2,
	h3,
	h4 {
		font-weight: 500;
		margin-bottom: 5px;
	}
}
div[class*='alert--'] {
	border-left-width: 10px;
	border-style: solid;
	background-repeat: no-repeat;
	background-position: top 13px left 10px;
	background-color: $tan;
	background-size: 20px;
	padding-left: 40px;
}
.alert--success {
	border-left-color: $green;
	background-image: url('../../assets/images/alert_success.svg');
	h1,
	h2,
	h3,
	h4 {
		color: $green;
	}
}
.alert--info {
	border-left-color: $blue--staff;
	h1,
	h2,
	h3,
	h4 {
		color: $blue--staff;
	}
	background-image: url('../../assets/images/alert_info.svg');
}
.alert--warning {
	border-left-color: $yellow--gold;
	h1,
	h2,
	h3,
	h4 {
		color: $yellow--gold;
	}
	background-image: url('../../assets/images/alert_warning.svg');
}
.alert--danger {
	border-left-color: $red;
	h1,
	h2,
	h3,
	h4 {
		color: $red;
	}
	background-image: url('../../assets/images/alert_danger.svg');
}
.alert__content {
	display: inline-block;
}

.alert__close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 0.6;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
