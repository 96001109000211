/*
	
########     ###     ######   #### ##    ##    ###    ######## ####  #######  ##    ## 
##     ##   ## ##   ##    ##   ##  ###   ##   ## ##      ##     ##  ##     ## ###   ## 
##     ##  ##   ##  ##         ##  ####  ##  ##   ##     ##     ##  ##     ## ####  ## 
########  ##     ## ##   ####  ##  ## ## ## ##     ##    ##     ##  ##     ## ## ## ## 
##        ######### ##    ##   ##  ##  #### #########    ##     ##  ##     ## ##  #### 
##        ##     ## ##    ##   ##  ##   ### ##     ##    ##     ##  ##     ## ##   ### 
##        ##     ##  ######   #### ##    ## ##     ##    ##    ####  #######  ##    ## 

*/

ul.pagination {
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
	a {
		position: relative;
		display: block;
		padding: 0.5rem 0.75rem;
		margin-left: -1px;
		line-height: 1.25;
		color: $gray--text;
		background-color: $white;
		border: 1px solid $gray--border;
		text-decoration: none;
		&:hover {
			background-color: $blue--staff;
			color: $white;
		}
	}
	li.disabled {
		a {
			color: $gray--border;
			pointer-events: none;
			cursor: auto;
			background-color: #fff;
			border-color: #dee2e6;
		}
	}
	li.active {
		a {
			background-color: $blue--very-light;
		}
	}
}
