.link--tabs {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}
.link--tabs__button {
	font-size: 1em;
	width: 100% !important;
	display: block;
	margin-top: 10px;
	@media (min-width: $breakpoint-xl) {
		margin-top: 0;
		margin-left: auto;
		line-height: 1.5;
		width: auto !important;
		display: inline-block;
	}
}
.link--tab {
	width: 100%;
	padding: 5px 8px;
	margin-bottom: 1px;
	background: #e4ebf0;
	cursor: pointer;
	font-size: 1.5em;
	transition: background 0.1s, color 0.1s;
	display: inline-block;
	text-decoration: none !important;

	@media (min-width: $breakpoint-lg) {
		width: auto;
		min-width: 200px;
		padding: 10px 16px;
		margin-right: 10px;
		font-size: 1.5em;
	}
}
.link--tab:active {
	background: #466b93;
}
.link--tab:hover {
	background: #466b93;
	color: $white !important;
}
.link--tab-active {
	background: #fff;
	color: $white;
	background: #466b93;
}
.link--tab__subtitle {
	font-size: 0.7em;
	margin-top: 5px;
}
