.header {
	.mobile__nav {
		.nav__toggle {
			position: relative;
			display: inline-block;
			visibility: hidden;
			width: 36px;
			height: 15px;
			-webkit-tap-highlight-color: transparent !important;
			&::after,
			&::before {
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				visibility: visible;
			}
			&::before {
				top: calc(50% - 1.5px);
				border-top: 2px solid $blue--staff;
			}
			&::after {
				border-top: 2px solid $blue--staff;
				border-bottom: 2px solid $blue--staff;
			}
			@media (min-width: $breakpoint-lg) {
				display: none;
			}
		}
		&.open {
			.nav__toggle {
				position: absolute;
				right: 32px;
				top: 32px;
				width: 32px;
				height: 32px;
				&::before,
				&::after {
					left: 14px;
					height: 33px;
					width: 2px;
					background-color: $blue--navy;
				}
				&::before {
					top: auto;
					border: 0;
					transform: rotate(45deg);
				}
				&::after {
					border: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}
}
.nav {
	background-color: #eff3f6;
	position: relative;
	.mobile__login {
		padding: 20px;
		text-align: center;
		.btn--blue {
			background-color: $blue--middle;
			margin: 0 auto;
			display: inline-block;
			padding-left: 40px;
			padding-right: 40px;
			@include box-shadow(0 4px 10px 4px rgba(52, 52, 52, 0.15));
		}
		@media (min-width: $breakpoint-lg) {
			display: none;
		}
	}
	ul.navbar-nav {
		display: none;
		&.open {
			@media (max-width: ($breakpoint-lg - 1)) {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				overflow-y: scroll;
				z-index: 9;
				background: $white;
				box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
				background-color: #f9f9f9;
			}
		}
		@media (min-width: $breakpoint-lg) {
			display: flex;
			justify-content: flex-end;
			margin: 0;
			padding: 0;
			font-family: 'Fira Sans';
			font-weight: 500; // medium weight
		}

		li {
			position: relative;
			> a {
				color: $blue--staff;
				display: block;
				text-decoration: none;
				font-size: 1em;
				padding: 10px 20px;
				border-bottom: 1px solid rgba(29, 58, 90, 0.3);
				&:hover {
					//	background-color: $blue--very-light;
				}
				&.active {
					> a {
						border-bottom: solid 4px $yellow--light;
					}
				}
				@media (min-width: $breakpoint-lg) {
					color: $gray--nav;
					font-size: 1.1rem;
					margin-left: 30px;
					padding: 20px 10px 16px;
					border-bottom: 4px solid transparent;
					&:hover {
						border-bottom: solid 4px $yellow--light;
					}
				}
				@media (min-width: $breakpoint-xl) {
					font-size: 1.35rem;
				}
			}
			&.active {
				> a {
					border-left: solid 5px $yellow--light;
				}
				@media (min-width: $breakpoint-lg) {
					> a {
						border-left: 0;
						border-bottom: solid 4px $yellow--light;
					}
				}
			}
			&.dropdown {
				> a:hover {
					border-bottom: 0;
				}
			}
			&:last-child a {
				@media (min-width: $breakpoint-lg) {
					padding-left: 0;
					padding-right: 0;
				}
			}
			.mobile__submenu-button {
				background-color: $blue--gray;
				/*	background-image: url(/assets/images/arrow--down.png);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				background-size: 20%;
			*/
				border-left: 0px solid #ddd;
				border-bottom: 1px solid $blue--navy;
				display: block;
				width: 50px;
				height: 100%;
				height: 53px;
				right: 0;
				top: 0;
				position: absolute;
				&::before {
					border-style: solid;
					border-width: 0.1em 0.1em 0 0;
					content: '';
					display: inline-block;
					height: 0.45em;
					left: 1em;
					position: relative;
					top: 1em;
					transform: rotate(135deg);
					vertical-align: top;
					width: 0.45em;
					color: $white;
				}
				@media (min-width: $breakpoint-lg) {
					display: none;
				}
			}
			&.mobile__my-account {
				@media (min-width: $breakpoint-lg) {
					display: none;
				}
			}
		}

		.dropdown {
			position: relative;
		}
		.dropdown-toggle::after {
			@media (min-width: $breakpoint-lg) {
				display: inline-block;
				margin-left: 0.255em;
				vertical-align: 0.255em;
				content: '';
				border-top: 0.3em solid;
				border-right: 0.3em solid transparent;
				border-bottom: 0;
				border-left: 0.3em solid transparent;
			}
		}
		.dropdown-menu {
			display: none;
			padding: 0;
			margin: 0;
			text-align: left;
			list-style: none;
			background-color: #f9f9f9;
			background-clip: padding-box;
			@media (min-width: $breakpoint-lg) {
				position: absolute;
				top: 100%;
				left: 30px;
				z-index: 1000;
				display: none;
				float: left;
				min-width: 16rem;
				@include box-shadow(0 2px 10px 0 rgba(0, 28, 58, 0.1));
				&.show {
					display: block;
				}
			}
			.dropdown-content {
				padding: 15px 30px;
				h3 {
					font-size: 1rem;
					color: $blue--staff;
					font-weight: 400;
				}
				p {
					font-weight: normal;
					font-size: 1rem;
				}
				.btn {
					margin-bottom: 10px;
					font-size: 1rem;
				}
				@media (min-width: $breakpoint-lg) {
					padding: 30px;
					h3 {
						font-size: 1.4rem;
					}
					p {
						font-size: 1.1rem;
					}
					.btn {
						margin-bottom: 0;
					}
				}
			}
			.dropdown-item {
				display: block;
				padding: 10px 20px 10px 30px;
				font-size: 0.8em;
				text-decoration: none;
				width: 100%;
				clear: both;
				font-weight: 400;
				color: $gray--text;
				text-align: inherit;
				white-space: nowrap;
				background-color: $blue--very-light;
				border-left: 5px solid transparent;
				&:hover,
				&.active {
					background-color: $blue--gray;
					color: $white;
					border-left: 5px solid $yellow--light;
				}
				@media (min-width: $breakpoint-lg) {
					padding: 20px 30px;
					color: $blue--staff;
					&.active {
						background-color: $blue--navy;
					}
				}
			}
		}
		li.mobile__submenu--open {
			border: 1px solid $blue--navy;
			.mobile__submenu-button {
				width: 49px;
			}
			.dropdown-menu {
				display: block;
			}
		}
		.navbar-nav .dropdown-menu {
			position: absolute;
			float: none;
		}
	}
}
