/*
 ######   ########  #### ########  
##    ##  ##     ##  ##  ##     ## 
##        ##     ##  ##  ##     ## 
##   #### ########   ##  ##     ## 
##    ##  ##   ##    ##  ##     ## 
##    ##  ##    ##   ##  ##     ## 
 ######   ##     ## #### ########  
*/

.grid {
	width: 95%;
	margin: auto;
	//	max-width: 1140px;

	.grid {
		width: 100%;
	}

	@media (min-width: $breakpoint-md) {
		width: 90%;
	}
}

.grid--outlined {
	.grid__row {
		padding: $margin-unit;
	}
	.grid__col {
		background-color: rgba($gray--text, 0.1);
	}
}

.grid__row {
	display: flex;
	flex-flow: wrap;

	& > * {
		flex: 1 1 100%;
		width: 100%;
		word-wrap: break-word;
	}

	@media (min-width: $breakpoint-md) {
		& > * {
			flex: 0 0 100%;
		}
	}
}

.grid--striped,
.grid__row--striped {
	border-bottom: 1px solid $gray--border;
}

.grid__col {
	padding: 0 8px;

	& .grid__col {
		padding: 3px 8px;
		@media (min-width: $breakpoint-lg) {
			padding: 0;
		}
	}

	&:empty {
		padding: 0;
	}

	@media (min-width: $breakpoint-md) {
		& .grid__col {
			padding: 0 8px;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}
}

.grid__col--auto {
	-webkit-flex: 1 0 0px;
	-ms-flex: 1 0 auto;
	-ms-flex: 1 0 0px;
	flex: 1 0 0px;
	width: auto;
	max-width: 100%;
}

@each $prefix, $breakpoint in('' $breakpoint-md, '-md' $breakpoint-md, '-lg' $breakpoint-lg, '-xl' $breakpoint-xl) {
	@for $i from 12 through 1 {
		.grid__col#{$prefix}--#{$i} {
			@media (min-width: $breakpoint) {
				flex-basis: $grid-unit-relative * $i;
				max-width: $grid-unit-relative * $i;
				flex: 0 0 100%;
			}
		}
	}
}
@each $prefix, $breakpoint in('' $breakpoint-sm, '-md' $breakpoint-md, '-lg' $breakpoint-lg, '-xl' $breakpoint-xl) {
	@for $i from 12 through 1 {
		.grid__col#{$prefix}-offset--#{$i} {
			@media (min-width: $breakpoint) {
				margin-left: $grid-unit-relative * $i;
			}
		}
	}
}
@each $prefix, $breakpoint in('' $breakpoint-sm, '-md' $breakpoint-md, '-lg' $breakpoint-lg, '-xl' $breakpoint-xl) {
	@for $i from 12 through 1 {
		.grid--wrap__col#{$prefix}--#{$i} {
			@media (min-width: $breakpoint) {
				flex-basis: $grid-unit-relative * $i;
				max-width: $grid-unit-relative * $i;
			}
		}
	}
}
@each $prefix, $breakpoint in('' $breakpoint-sm, '-md' $breakpoint-md, '-lg' $breakpoint-lg, '-xl' $breakpoint-xl) {
	@for $i from 12 through 1 {
		.grid--wrap__col#{$prefix}-offset--#{$i} {
			@media (min-width: $breakpoint) {
				margin-left: $grid-unit-relative * $i;
			}
		}
	}
}

.grid__col-offset-sidebar {
	padding-left: $margin-unit;
}
