/*
##     ## #### ##     ## #### ##    ##  ######  
###   ###  ##   ##   ##   ##  ###   ## ##    ## 
#### ####  ##    ## ##    ##  ####  ## ##       
## ### ##  ##     ###     ##  ## ## ##  ######  
##     ##  ##    ## ##    ##  ##  ####       ## 
##     ##  ##   ##   ##   ##  ##   ### ##    ## 
##     ## #### ##     ## #### ##    ##  ######  
*/

@mixin background-image--cover() {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

@mixin background-image--contain() {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}
@mixin box-shadow($value) {
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	box-shadow: $value;
}

@mixin transition--fade() {
	transition: all 0.2s ease;
}

@mixin text-shadow() {
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}

@mixin text-overflow-ellipsis() {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@mixin gradient--opacity() {
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

@mixin h1() {
	font-size: 1.7em;
	font-weight: 700;
	margin-bottom: 0.8em;
}

@mixin h2() {
	font-size: 1.8rem;
	font-weight: 300;
	margin-top: 1em;
	margin-bottom: 0.8em;
	color: $blue--middle;
}

@mixin h3() {
	font-size: 1.2em;
	font-weight: 700;
	margin-bottom: 0.8em;
	color: $blue--staff;
}

@mixin h4() {
	font-size: 1.1em;
	margin-bottom: 0.8em;
}

@mixin h5() {
	font-size: 1em;
	margin-bottom: 0.8em;
}

@mixin h6() {
	font-size: 0.8em;
	margin-bottom: 0.8em;
}

@mixin code() {
	font-family: monospace;
	background-color: $gray--nav;
	display: inline-block;
	margin-bottom: 0.8em;
	font-size: 1em;
}

@mixin font--sans-serif() {
	font-family: 'Lato', 'Arial', sans-serif;
}

@mixin link() {
	color: $blue--middle;
	outline: none;
	cursor: pointer;
}

@mixin context() {
	font-size: 0.7em;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	color: $gray--text-light;
	margin-bottom: 0.5em;
}

@mixin label() {
	font-size: 0.8em;
	color: $gray--text-light;
}

@mixin strong() {
	font-weight: 700;
}

@mixin form__full-width() {
	display: flex;
	flex-direction: column;
}

@mixin paragraph() {
	line-height: 1.6;
	margin-bottom: 1em;
	font-size: 1.25rem;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin card() {
	@include box-shadow(7px 7px 30px 2px rgba(0, 0, 0, 0.1));
	background: #fff;
	width: 100%;
	h2 {
		margin-bottom: 10px;
	}
}
@mixin card--tan() {
	background-color: #f2f2f2;
	@include box-shadow(3px 3px 5px 2px rgba(0, 0, 0, 0.1));
}
