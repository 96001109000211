/*
########  #######  ########  ##     ## 
##       ##     ## ##     ## ###   ### 
##       ##     ## ##     ## #### #### 
######   ##     ## ########  ## ### ## 
##       ##     ## ##   ##   ##     ## 
##       ##     ## ##    ##  ##     ## 
##        #######  ##     ## ##     ## 
*/

/* ********** LOGIN FORM IN GLOBAL HEADER ************ */
.login {
	display: none;
	@media (min-width: $breakpoint-lg) {
		display: block;
		.input,
		.submit {
			padding: 12px 20px;
			font-size: 0.8em;
		}
		.input {
			padding: 10px 18px;
		}
		.btn {
			margin-bottom: 0;
			font-size: 0.8rem;
		}
		.login__title,
		.login__forgot {
			color: #72a3c3;
		}
		.login__title {
			font-size: 1.1rem;
			margin-bottom: 10px;
		}
		.login__forgot {
			display: block;
			text-align: right;
			font-size: 0.8rem;
			margin: 5px 0 15px 0;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		.alert {
			margin-bottom: -20px;
		}
	}
	@media (min-width: $breakpoint-xl) {
		.btn {
			font-size: 0.9rem;
		}
	}
}

fieldset {
	margin: 20px 0;
}

.form__element {
	margin: 20px 0;
}

input,
textarea,
select {
	border: 2px solid $gray--border;
	border-radius: 0;
	background-color: $white;
	color: #555;
	padding: 9px 10px;
	font-size: 0.9rem;
	line-height: 1.2;
	max-width: 100%;
	width: 100%;
	margin-top: 20px;
	@media (min-width: $breakpoint-lg) {
		margin-top: 0;
	}
}
input + input,
input + .btn {
	@media (min-width: $breakpoint-lg) {
		margin-left: 0.5em;
	}
}
.table input {
	width: 100%;
}
label + input {
	margin-top: 0;
}

/* ********** SELECT DROPDOWNS ************ */

select {
	display: block;
	margin-bottom: 20px;
	@media (min-width: $breakpoint-lg) {
		margin-bottom: 0;
		display: inline-block;
	}
	max-width: 100%;
	height: auto;
	background: url('/assets/images/arrow--down.png') right 10px center no-repeat #fff;
	background-size: 16px;
	padding-right: 40px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
select + select,
select + .btn {
	margin-bottom: 20px;
	@media (min-width: $breakpoint-lg) {
		margin-bottom: 0;
		margin-left: 20px;
	}
}
/* body select.select_box option */
select option {
	padding: 0 4px;
}
/* for IE and Edge */
select::-ms-expand {
	display: none;
}
select:disabled::-ms-expand {
	background: #f60;
}

/* ********** LABEL ************ */
/* The container */
.label {
	display: block;
	position: relative;
	padding-top: 5px;
	padding-left: 0px;
	margin-bottom: 10px;
	cursor: pointer;
	font-weight: 500;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
label {
	font-weight: 500;
	display: block;
	margin-bottom: 5px;
	line-height: 1;
	font-size: 1rem;
}
/* Hide the browser's default checkbox */
.label input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.label textarea {
	padding-left: 0;
}
.label--checkbox,
.label--radio {
	padding-left: 30px;
}

/* ********** CHECKBOXES ************ */
/* Create a custom checkbox */
.checkbox input {
	margin: auto;
	width: auto;
}
.checkbox .label,
.label.checkbox {
	padding-left: 30px;
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: $white;
	border: 1px solid $gray--border;
}

/* On mouse-over, add a grey background color */
.label:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.label input:checked ~ .checkmark {
	background-color: #2196f3;
}

/* When the checkbox and input are disabled */
.label input:disabled ~ .checkmark {
	background-color: #999;
	opacity: 0.7;
	border: 0;
	cursor: not-allowed;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.label input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.label .checkmark:after {
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* ********** RADIO BUTTONS ************ */
/* Create a custom radio button */
.radio {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: $white;
	border: 2px solid $gray--border;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.label:hover input ~ .radio {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.label input:checked ~ .radio {
	background-color: #2196f3;
}

/* When the radio and input are disabled */
.label input:disabled ~ .radio {
	background-color: #999;
	opacity: 0.7;
	border: 0;
	cursor: not-allowed;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.label input:checked ~ .radio:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.label .radio:after {
	top: 5px;
	left: 5px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: white;
}

.label--uppercase {
	.label {
		text-transform: uppercase;
		padding-left: 0;
	}
}

.input--inline {
	input,
	select {
		& + .btn {
			margin-top: 20px;
		}
		@media (min-width: $breakpoint-lg) {
			width: auto;
			margin-right: 5px;
			margin-bottom: 5px;
			& + .btn {
				margin-top: 0px;
			}
		}
	}
}

input,
select,
textarea {
	&.error {
		color: $red;
		border-color: $red;
	}
}

rd-abs-submit-field.error {
	label {
		color: $red;
	}
	.grid__input {
		input {
			color: $red;
			border-color: $red;
		}
	}
}

.input--currency {
	.grid__input {
		position: relative;
		display: inline;
		&:before {
			content: '$';
			position: absolute;
			top: 12px;
			left: 20px;
			z-index: 1;
			@media (min-width: $breakpoint-md) {
				//	top: 32px;
			}
			@media (min-width: $breakpoint-lg) {
				top: 12px;
			}
		}
		input {
			padding-left: 30px;
		}
	}
}

/* ************ Disabled Form elements ************* */

input:disabled {
	border-color: transparent;
	background-color: transparent;
}
select:disabled,
textarea:disabled {
	background-color: $gray--border;
	opacity: 0.4;
	cursor: not-allowed;
}

input[readonly] {
	background-color: $gray--border;
	opacity: 0.5;
	color: #000;
	cursor: not-allowed;
}

/* ************ Export Report History elements ************* */

#historical-spreadsheets {
	.export--report-combobox.ng-select .ng-select-container {
		border-radius: 0;
		border-width: 2px;
		border-color: $gray--border;
		.ng-value-container {
			.ng-placeholder {
				top: auto;
			}
			.ng-value {
				padding: 5px;
				background-color: #eee;
				border-color: $gray--border;
			}
		}
	}
	h3 {
		margin-top: 50px;
		margin-bottom: 0;
	}
}
.contact__add-delete {
	margin: 20px;
	padding: 20px;
	border: 1px solid $gray--border;
	background: #eee;
}
