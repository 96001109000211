.container {
	margin: 0 auto;
	padding-left: 30px;
	padding-right: 30px;
	@media (min-width: $breakpoint-xl) {
		max-width: 1440px;
		//	padding: 0;
	}
}

/* 
##     ## ########    ###    ########  ######## ########  
##     ## ##         ## ##   ##     ## ##       ##     ## 
##     ## ##        ##   ##  ##     ## ##       ##     ## 
######### ######   ##     ## ##     ## ######   ########  
##     ## ##       ######### ##     ## ##       ##   ##   
##     ## ##       ##     ## ##     ## ##       ##    ##  
##     ## ######## ##     ## ########  ######## ##     ## 
*/

.header {
	background-image: linear-gradient(to bottom, #dce3e8, #e9edf2); //c6d4df 	// #aac1d8
	padding: 30px 0;
	@media (min-width: $breakpoint-md) {
		padding: 45px 0;
	}
	@media (min-width: $breakpoint-lg) {
		padding: 60px 0;
	}
}
.brand {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logo {
	flex-basis: 200px;
	img {
		//	max-width: 444px;
		width: 100%;
		margin-right: 30px;
	}
	@media (min-width: $breakpoint-md) {
		flex-basis: 280px;
	}
	@media (min-width: $breakpoint-xl) {
		flex-basis: auto;
	}
}
.body__title {
	text-align: center;
	width: 100%;
	background-color: $blue--gray;
	padding: 25px 30px;
	@include box-shadow(0px 2px 50px 0px rgba(0, 0, 0, 0.2));
	.breadcrumbs {
		margin-bottom: 35px;
		font-weight: 500;
		color: #fff;
		a {
			text-decoration: none;
			font-weight: 200;
			color: #fff;
			&:hover {
				text-decoration: underline;
				color: $white;
			}
			&::after {
				content: '/';
				margin: 0 5px;
				display: inline-block;
			}
			&:first-child {
				padding-left: 25px;
				background: url('/assets/images/home.svg') no-repeat top left;
			}
		}
	}
	h1 {
		@include h1();
		font-weight: 300;
		margin: 0;
		padding: 0;
		color: $white;
		@media (min-width: $breakpoint-lg) {
			font-size: 2.75rem;
		}
	}
}
.body {
	a {
		text-decoration: underline;
		&:hover {
			color: $yellow--gold;
		}
	}
	ul {
		list-style-type: disc;
		margin: 1em;
		padding-left: 0.5em;
		list-style-position: outside;
		li {
			list-style-position: outside;
			margin-bottom: 0.7em;
		}
		@media (min-width: $breakpoint-lg) {
			margin: 2em;
			padding-left: 1em;
		}
	}
}
.content {
	padding-top: 30px;
	padding-bottom: 50px;
	@media (min-width: $breakpoint-md) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@media (min-width: $breakpoint-xl) {
		padding: 80px 30px;
	}
}
.text--right {
	@media (min-width: $breakpoint-lg) {
		text-align: right;
	}
}
.important {
	@include strong();
}
.float--right {
	@media (min-width: $breakpoint-lg) {
		float: right;
		margin: 0px 0 20px 20px;
	}
}
.comments {
	border: 1px solid $gray--border;
	padding: 30px;
	background: url('/assets/images/comment.svg') no-repeat top 30px right 30px;
	background-size: 30px 30px;
	@media (min-width: $breakpoint-lg) {
		background-size: 44px 44px;
	}
	.comment--entry {
		margin-top: 30px;
	}
	.comment--title {
		color: $blue--staff;
		display: inline;
		font-weight: 500;
	}
	.comment--date {
		color: #999;
		display: inline;
		margin-left: 10px;
	}
	.comment--content {
		margin-top: 5px;
	}

	textarea {
		margin: 30px 0 20px 0;
		width: 100%;
		height: 200px;
		border: 1px solid $gray--border;
	}
	.btn {
		margin-top: 20px;
		margin-bottom: 0;
	}
}

.meet--team {
	display: flex;
	justify-content: space-between;
	.team__member {
		width: 30%;
		margin-top: 0.4rem;
	}
	.team__image {
		width: 100%;
		margin-bottom: 0.4rem;
	}
	.team__name {
		font-size: 1.4rem;
		color: $blue--staff;
		margin-bottom: 0.4rem;
	}
	.team__title {
		font-weight: 500;
		margin-bottom: 0.4rem;
	}
	.team__email {
		text-decoration: none;
	}
	.team__bio {
		margin-top: 20px;
	}
}

.footer__wrapper {
	background-color: $blue--navy;
	padding: 70px 0px 50px 0px;
	color: #e8e8e8;
	@media (min-width: $breakpoint-lg) {
		padding: 85px 0;
	}
}
.footer {
	display: grid;
	grid-template-columns: repeat(12, [col-start] 1fr);
	grid-gap: 20px;
	h2 {
		font-size: 26px;
		color: $blue--light;
	}
	a {
		color: $blue--light;
		&:hover {
			color: $white;
		}
	}
}
.footer__left {
	grid-column: col-start 1 / span 12;
	margin-bottom: 50px;
	@media (min-width: $breakpoint-lg) {
		grid-column: col-start 2 / span 4;
		margin-bottom: 0;
	}
}
.contact a {
	text-decoration: none;
	color: $white;
	font-size: 1.3em;
	padding-left: 30px;
	margin-top: 5px;
	background: url('/assets/images/phone.svg') no-repeat top 3px left;
}
.naccap_footer_logo {
	width: 100%;
	max-width: 375px;
	margin-bottom: 20px;
}
.copyright {
	margin-bottom: 20px;
}
.footer__right {
	grid-column: col-start 1 / span 12;
	@media (min-width: $breakpoint-lg) {
		grid-column: col-start 8 / span 4;
	}
	.btn--ghost--white {
		border-color: $blue--staff !important;
	}
}
.export-report {
	@media (min-width: $breakpoint-lg) {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
}
.chartjs-render-monitor {
	@media (max-width: ($breakpoint-lg - 1px)) {
		margin-top: 20px;
	}
}
