.btn {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	padding: 12px 20px;
	line-height: 1;
	display: block;
	text-align: center;
	box-shadow: none;
	text-decoration: none !important;
	margin-bottom: 20px;
	width: auto !important;
	cursor: pointer;
	border: 0;
	&:hover {
		@include opacity(0.7);
	}
	@media (min-width: $breakpoint-md) {
		font-size: 0.9rem;
		display: inline-block;
	}
}
.btn--blue {
	background-color: $blue--navy;
	color: $white !important;
}
.btn--gold {
	background-color: $yellow--gold;
	color: $white !important;
}
.btn--gray {
	background-color: $gray--border;
	color: $gray--text !important;
}
.btn--ghost {
	background-color: transparent;
	padding: 10px 18px;
	border-width: 2px;
	border-style: solid;
	border-color: $gray--border;
	&:hover {
		background-color: rgba(238, 238, 238, 0.2);
	}
	@media (min-width: $breakpoint-lg) {
		margin-right: 5px; //assumes ghost buttons always accompany solid btn, with solid btn bottom or right of container
	}
}
.btn--ghost--blue {
	color: #72a3c3 !important;
	border-color: #72a3c3 !important;
}
.btn--ghost--gold {
	color: $yellow--gold !important;
	border-color: $yellow--gold !important;
}
.btn--ghost--white {
	color: $white !important;
	border-color: $white !important;
}

input.btn {
	border: 0;
	&.btn--ghost {
		border: 2px solid $gray--border;
	}
}
.btn:disabled {
	opacity: 0.5;
	background-color: #666;
	cursor: not-allowed;
}

.forgot__password__panel__action .btn {
	margin-top: 20px;
}
