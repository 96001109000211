/*
	
########  ########  #### ##    ## ######## 
##     ## ##     ##  ##  ###   ##    ##    
##     ## ##     ##  ##  ####  ##    ##    
########  ########   ##  ## ## ##    ##    
##        ##   ##    ##  ##  ####    ##    
##        ##    ##   ##  ##   ###    ##    
##        ##     ## #### ##    ##    ##    

*/

@media print {
	.login,
	.link--tab,
	.link--tabs__button,
	.footer__right,
	.btn,
	.naccap_footer_logo,
	.comments textarea {
		display: none;
	}
	.link--tab-active {
		display: block;
	}
	body {
		font-size: 0.85rem;
		-webkit-print-color-adjust:exact;
	}
	body *,
	.body__title,
	.card {
		@include box-shadow(none);
	}
	.body__title h1 {
		color: #000;
	}
	.content {
		padding: 40px 0 0 0;
	}
	.footer__wrapper {
		padding: 10px 0 0 0;
		border-top: 1px solid $gray--border;
	}
	.help__card--inactive {
		display: block;
	}
	body,
	h1,
	h2,
	h3,
	ol,
	ul,
	div {
		width: auto;
		border: 0;
		margin: 0 5%;
		padding: 0;
		float: none;
		position: static;
		overflow: visible;
		line-height: 1;
	}
}
