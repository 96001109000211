/*

######## ##     ## ##    ## ##    ## ######## ##       
##       ##     ## ###   ## ###   ## ##       ##       
##       ##     ## ####  ## ####  ## ##       ##       
######   ##     ## ## ## ## ## ## ## ######   ##       
##       ##     ## ##  #### ##  #### ##       ##       
##       ##     ## ##   ### ##   ### ##       ##       
##        #######  ##    ## ##    ## ######## ######## 
         
*/

/* ********** FUNNEL SUMMARY PAGE ************ */

.funnel--summary {
	.grid__row {
		align-items: center;
	}
	.hr--shadow {
		margin: 50px 0;
	}
	table[id^='data-'] {
		th,
		td {
			text-align: right;
			&:first-child {
				text-align: left;
			}
		}
	}
}

/* ********** FUNNEL SUBMIT PAGE ************ */

.funnel--submit {
	th,
	td {
		background: $blue--very-light;
	}
	.card {
		font-size: 0.9em;
		line-height: 0.6;
		//	padding: 30px;
		p {
			font-size: 1rem;
		}
	}

	.ms-0 {
		margin-left: 0!important;
	}
}

/* ********** FUNNEL REPORT PAGE ************ */

.table__filters {
	clear: both;
	background: $blue--navy;
	color: #fff;
	padding: 20px;
	border-bottom: 2px solid $white;
	@media (min-width: $breakpoint-lg) {
		display: flex;
		align-items: center;
		padding: 30px;
	}
	h1,
	h2,
	h3,
	h4 {
		margin-bottom: 0;
	}
	.table__filters--search {
		background: url('/assets/images/search.png') no-repeat 10px center #fff;
		background-size: 22px;
		padding: 10px 10px 10px 40px;
		width: 300px;
		border: 2px solid $gray--border;
	}
	.table__filters--title {
		font-size: 1.75rem;
		color: $white;
	}
	.table__filters--past {
		margin-left: auto;
		> select {
			@media (min-width: $breakpoint-lg) {
				margin-left: 20px;
			}
		}
		.btn {
			margin-bottom: 0;
		}
	}
	input,
	select {
		width: 100%;
		@media (min-width: $breakpoint-lg) {
			width: auto;
		}
	}
}
.table__filters--skinny {
	padding: 15px 30px;
	input {
		padding: 7px 10px;
	}
	select {
		padding: 7px 40px 7px 10px;
	}
}
.funnel--report {
	display: block;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	margin-bottom: 40px;
	width: 100%;
	border: none;
	font-size: 0.8rem;
	table {
		width: 100%;
	}
	thead th {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
	}
	tbody {
		tr {
			td {
				text-align: center;
				vertical-align: middle;
				padding: 2px;
			}
		}
	}
	.funnel--report__header {
		.funnel--report__leftcolumn {
			background: #001c3a;
			color: #fff;
			vertical-align: top;
		}
	}
	.funnel--report__leftcolumn,
	.funnel--report__column--category,
	.funnel--report__column--last {
		background: #001c3a;
		color: #fff;
	}
	th.funnel--report__column--category {
		width: 27%;
		text-transform: uppercase;
		border-top: 0;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		border-left: 1px solid #fff;
		padding: 10px 0 0 0;
		table {
			margin-top: 10px;
		}
	}
	td.funnel--report__column--funnel {
		width: 25%;
		background: $blue--staff;
		text-transform: none;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		border-left: 2px solid #fff;
		padding: 7px 0;
		&:first-child {
			border-left: 0;
		}
		&:last-child {
			border-right: 0;
		}
	}
	td.funnel--report__column--year {
		background: $blue--light;
		color: #000;
		width: 12.5%;
		padding: 7px 0;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		border-bottom: 0px solid #fff;
		border-left: 2px solid #fff;
		padding: 7px 0;
		&:first-child {
			border-left: 0;
		}
		&:last-child {
			border-right: 0;
		}
	}
	.funnel--report__leftcolumn {
		width: 10%;
		background: $blue--staff;
		text-align: left;
		padding: 10px;
		border-bottom: 2px solid #fff;
		position: sticky;
		left: 0;
		z-index: 2;
		.checkbox {
			float: left;
			margin: 0 5px 15px 0;
		}
	}
	.funnel--report__column--last {
		width: 3%;
		border-top: 0;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		border-left: 1px solid #fff;
	}
	th:first-child {
		position: -webkit-sticky;
		position: sticky;
		left: 0;
		z-index: 2;
		background: #ccc;
	}
	thead th:first-child,
	tfoot th:first-child {
		z-index: 5;
	}
}
